import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { I } from '../../common/v5/config.js';
import {
	AS_AVAILABLE,
	MM_ADMINISTRATION,
	MM_SEARCH,
	MM_WORKFLOW,
	PREF_CONVERSATION_VIEW,
	COMMON_HOTKEYS,
	WORKFLOW_HOTKEYS,
	WORKFLOW_HOTKEYS_LIST_VIEW,
	SEARCH_HOTKEYS,
	ERRAND_HOTKEYS,
	MANUAL_ERRAND_HOTKEYS,
	CHAT_ERRAND_HOTKEYS
} from '../../common/v5/constants';
import { noSelector } from './common';
import { isManualCallPopup, isManualPopup } from './manual';
import { getMainMenuById } from './server';
import { getWorkflowSettingsData } from './workflow';

const uiData = state => state.app.header.uiData;
const getPickupNext = state => uiData(state).pickUpNext;
export const getCurrentStatus = state => state.app.header.currentStatus.data;
const getServerRightMenuStatus = state => state.server.menu.rightMenu[0];
const getAgentStatus = state => state.app.header.agentStatus;

export const getAgentStatusData = createSelector(
	getAgentStatus,
	({data}) => {
		if (!data) {
			return [];
		}
		return data;
	}
);

export const getAgentStatusById = createSelector(
	getAgentStatus,
	({byId}) => {
		if (!byId) {
			return [];
		}
		return byId;
	}
);

export const getAgentStatusByName = createSelector(
	getAgentStatus,
	({byName}) => {
		if (!byName) {
			return [];
		}
		return byName;
	}
);

export const alertMemo = state => uiData(state).alert;

export const alertData = state => alertMemo(state).data;

export const getActiveMainView = state => state.app.menu.mainMenu.activeMainMenu;

const checkErrandOpen = state => state.app.errand.currentErrand.id;
const checkChatOpen = state => state.app.errand.currentChatErrandId;
const checkWfView = state => state.app.workflow.fetchWfSettings.data ? state.app.workflow.fetchWfSettings.data.agentErrandListViewPref : PREF_CONVERSATION_VIEW;
const checkManualErrand = state => isManualPopup(state);
const checkManualCallErrand = state => isManualCallPopup(state);

//NOTE: This should be the selector to decide
//which set of hotkeys should be implemented
//base on which page user currently on
export const getValidKeysByViewsSelector = createSelector(
	[
		getActiveMainView,
		checkErrandOpen,
		checkChatOpen,
		checkWfView,
		checkManualErrand,
		checkManualCallErrand
	],
	(activeView, errandOpened, chatOpened, prefView, manualOpened, manualCallOpened) => {
		let keys = COMMON_HOTKEYS, keySet = WORKFLOW_HOTKEYS;
		if(activeView == MM_WORKFLOW){
			if(manualOpened || manualCallOpened){
				keySet = MANUAL_ERRAND_HOTKEYS;
			}else{
				if(errandOpened > 0) {
					if(chatOpened != -1){
						keySet = CHAT_ERRAND_HOTKEYS;
					}else{
						keySet = ERRAND_HOTKEYS;
					}
				}else{
					if(prefView === PREF_CONVERSATION_VIEW){
						keySet = WORKFLOW_HOTKEYS;
					}else{
						keySet = WORKFLOW_HOTKEYS_LIST_VIEW;
					}
				}
			}
		}else if(activeView == MM_SEARCH){
			keySet = SEARCH_HOTKEYS;
		}
		if(activeView == MM_WORKFLOW || activeView == MM_SEARCH){
			keys = COMMON_HOTKEYS.concat(keySet);
		}else{
			keys = COMMON_HOTKEYS;
		}
		return  keys;
	}
);

const agentDefaultStatusSelector = createSelector(
	[getServerRightMenuStatus, getAgentStatus],
	({id, label}, {byId}) => {
		if (byId && byId[id]) {
			return {status: byId[id].label, label, className: byId[id].className};
		} else {
			return {status: label, label};
		}
	}
);

export const agentStatusSelector = createSelector(
	[
		getCurrentStatus,
		getServerRightMenuStatus,
		agentDefaultStatusSelector,
		getAgentStatus,
	],
	(statusData, menu, defaultStatus, {data, byName}) => {
		if (!statusData || !statusData.status) {
			return defaultStatus;
		}
		let found, status, label, className;
		if (!data || !byName) {
			return defaultStatus;
		}
		$.each(data, (i,v) => {
			if (byName.hasOwnProperty(statusData.status) &&
				v.id === byName[statusData.status].id) {
				found = true;
				status = statusData.status;
				label = v.label;
				className = v.className;
				return false;
			}
		});
		if (found) {
			return {status, label, className};
		} else {
			return defaultStatus;
		}
	}
);

export const agentPickUpNext = createSelector(
	[agentStatusSelector, getPickupNext],
	(agentStatus, pickUpNext) => {
	let enablePickUpNext = true;
	enablePickUpNext = features["enablePickUpNext"];
	if (features["forced-pickup-next"] || enablePickUpNext) {
		if (pickUpNext || features["forced-pickup-next"]){
			if (agentStatus && agentStatus.status == AS_AVAILABLE) {
				return true;
			}
		}
		return false;
	}
	return pickUpNext;
});

export const agentNameSelector = createSelector(
	getWorkflowSettingsData,
	data => {
		if (!data) {
			return '';
		}
		return {name: data.activeUserName, photo: data.activeUserPhoto};
	}
);

const getChatConfigData = state => state.app.header.chatSource.data;

function isEnabled(serviceType, chatConfig){
	let enabled = true;
	if (chatConfig && typeof chatConfig[serviceType] !== "undefined") {
		enabled = chatConfig[serviceType];
	}
	return enabled;
}

export const hasChatSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data) {
			return false;
		}
		return $.inArray(Workflow.Errand.SERVICE_CHAT, data.chatSource) != -1;
	}
);

export const hasFBChatSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data){
			return false;
		}
		return $.inArray(Workflow.Errand.SERVICE_FACEBOOK, data.chatSource) != -1;
	}
);

export const hasTwitterChatSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data){
			return false;
		}
		return $.inArray(Workflow.Errand.SERVICE_TWITTER, data.chatSource) != -1;
	}
);

export const hasChatEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		let status = isEnabled(Workflow.Errand.SERVICE_CHAT, channelStatus);
		return status;
	}
);

export const hasFBChatEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		return isEnabled(Workflow.Errand.SERVICE_FACEBOOK, channelStatus);
	}
);

export const hasTwitterChatEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		return isEnabled(Workflow.Errand.SERVICE_TWITTER, channelStatus);
	}
);

export const hasLINEChatSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data){
			return false;
		}
		return $.inArray(Workflow.Errand.SERVICE_LINE, data.chatSource) != -1;
	}
);

export const hasLINEChatEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		return isEnabled(Workflow.Errand.SERVICE_LINE, channelStatus);
	}
);

export const hasWAChatSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data){
			return false;
		}
		return $.inArray(Workflow.Errand.SERVICE_WHATSAPP, data.chatSource) != -1;
	}
);

export const hasTGChatEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		return isEnabled(Workflow.Errand.SERVICE_TELEGRAM, channelStatus);
	}
);

export const hasTGChatSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data){
			return false;
		}
		return $.inArray(Workflow.Errand.SERVICE_TELEGRAM, data.chatSource) != -1;
	}
);

export const hasVBChatEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		return isEnabled(Workflow.Errand.SERVICE_VIBER, channelStatus);
	}
);

export const hasVBChatSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data){
			return false;
		}
		return $.inArray(Workflow.Errand.SERVICE_VIBER, data.chatSource) != -1;
	}
);

export const hasWAChatEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		return isEnabled(Workflow.Errand.SERVICE_WHATSAPP, channelStatus);
	}
);

export const hasIGChatEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		return isEnabled(Workflow.Errand.SERVICE_INSTAGRAM, channelStatus);
	}
);

export const hasIGChatSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data){
			return false;
		}
		return $.inArray(Workflow.Errand.SERVICE_INSTAGRAM, data.chatSource) != -1;
	}
);

export const hasEmailEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		let status = isEnabled(Workflow.Errand.SERVICE_EMAIL,
			channelStatus);
		return status;
	}
);

export const hasSipVoiceSelector = createSelector(
	getWorkflowSettingsData,
	(data) => {
		if(!data){
			return false;
		}
		return data.sipEnabled;
	}
);

export const hasSipVoiceEnabledSelector = createSelector(
	getChatConfigData,
	(channelStatus) => {
		let status = isEnabled(Workflow.Errand.SERVICE_SIP_VOICE,
			channelStatus);
		return status;
	}
);

const defaultChatSource = (state) => state.server.defaultChatServices;

export const getTotalChatChannelsSelector = createSelector(
	getWorkflowSettingsData,
	defaultChatSource,
	(data, chatConfigChannels) => {
		let totalChatChannels = 0;
		if(!data) {
			return totalChatChannels;
		}
		chatConfigChannels.forEach((ch) => {
			if ($.inArray(ch, data.chatSource) != -1) {
				totalChatChannels++;
			}
		});

		return totalChatChannels;
	}
);


export const getTotalChatChannelsActiveSelector = createSelector(
	getWorkflowSettingsData,
	getTotalChatChannelsSelector,
	getChatConfigData,
	defaultChatSource,
	(data,
		totalChannel,
		channelStatus,
		chatConfigChannels
	) => {
		let totalChannelsActivate = 0;
		if(!data){
			return totalChannelsActivate;
		}
		//if none is disabled, then set all channel activated
		if(!channelStatus){
			totalChannelsActivate = totalChannel;
		}else{
			chatConfigChannels.forEach((ch) => {
				if ($.inArray(ch, data.chatSource) != -1 && isEnabled(ch, channelStatus)) {
					totalChannelsActivate++;
				}
			});
		}

		return totalChannelsActivate;
	}
);

const createMainMenuPageChecker = mainMenuId => (menuId, mainMenuById) => {
	const menu = mainMenuById[menuId];
	if (!menu) {
		return false;
	}
	return menu.id === mainMenuId;
};

export const isMainMenuWorkflowErrand = createMainMenuPageChecker(MM_WORKFLOW);

const createMainMenuPageSelector = menuId => noSelector(
	getActiveMainView
	, getMainMenuById
	, (activeMainMenu, mainMenuById) => createMainMenuPageChecker(menuId)(
		activeMainMenu
		, mainMenuById
	)
);

export const isMainMenuWorkflowErrandSelector = createMainMenuPageSelector(MM_WORKFLOW);

export const isAdminPageSelector = createMainMenuPageSelector(MM_ADMINISTRATION);

export const activeMenuTitleSelector = createSelector(
	getActiveMainView
	, getMainMenuById
	, (activeMainMenu, mainMenuById) => {
		const menu = mainMenuById[activeMainMenu];
		if (!menu || !menu.label) {
			return I("Unknown");
		}
		return menu.label;
	}
);
