import { createSelector } from 'reselect'
import { emptyNormalized } from '../../common/constants'
import {
  keyDeleteAdminAgentsAccessKeys,
  keyGetAdminAgentsAccessKeys,
  keySaveAdminAgentsAccessKeys
} from '../constants/keys'
import { selectorsCreator, getActiveAdminId } from './admin'
import { noSelector } from './common'
import { getMyId } from './workflow'

export const BRANCH_NAME = 'agentAccessKeys'
export const BRANCH_KEYS = 'keysByAgent'

const selectors = selectorsCreator(BRANCH_NAME, [
  [keyGetAdminAgentsAccessKeys, 'get'],
  [keySaveAdminAgentsAccessKeys, 'save'],
  [keyDeleteAdminAgentsAccessKeys, 'delete']
])

export const {
  asyncMap: adminAsyncMap,
  root: adminRoot,
  stateName: adminStateName,
  stateByKey: adminStateByKey
} = selectors

export const keysByAgent = state => selectors.root(state)[BRANCH_KEYS]

const isPreferencesPropTrue = (_, { isPreferences }) => !!isPreferences

export const adminAgentAccessKeysSelector = createSelector(
  isPreferencesPropTrue,
  getMyId,
  getActiveAdminId,
  keysByAgent,
  (isPreferences, activeUserID, agentID, keys) => {
    if (isPreferences) {
      agentID = activeUserID
    }
    const agentKeys = keys[agentID]
    if (agentKeys) {
      return agentKeys
    }
    return emptyNormalized
  }
)
