import { createSelector } from 'reselect';
import update from 'immutability-helper';
import { getLocation } from 'connected-react-router';
import { emptyArray } from '../../common/v5/constants';
import { SELECT_ALL_SEARCH_RESULT} from '../../redux/constants/constants'
import { noSelector } from './common'

const appSearch = state => state.app.search;
const searchResults = state => appSearch(state).results;
const globalSearch = state => appSearch(state).ui.globalSearch;
const emptyData = {data: emptyArray};

export const getSelectedSearchErrandCount = createSelector(
	searchResults,globalSearch, (results, globalSearch) => {
		let allErrands = [], myErrands = [], closedErrands = [];
		let e = results.errands
		if (globalSearch.isCollaborations) {
			e = results.collabErrands
		}
		$.each(e, (i, v) => {
			if (results.opr[v.id] && results.opr[v.id].selected) {
				if(v.closedTime != "") {
					closedErrands.push(v.id);
				} else if (v.agentId == 0) {
					allErrands.push(v.id);
				} else {
					myErrands.push(v.id);
				}
			}
		});
		return {
			allErrands: allErrands.length,
			myErrands: myErrands.length,
			closedErrands: closedErrands.length
		};
	}
)

const inputsSearchCheckboxes = createSelector(
	appSearch,
	appSearch => appSearch.results
);

const searchResultsSelector = (state, props) => {
	let fd = state.app.search.results;
	if (!fd) {
		return emptyData;
	}
	return fd;
};

function getSearchResultsOprAll(state, props) {
	const { opr } = searchResultsSelector(state);
	if (!opr) {
		return false;
	}
	return !!opr.all;
};

export const searchCheckboxStatesSelector = createSelector(
	inputsSearchCheckboxes
	, getSearchResultsOprAll
	, (
		results
		, allSelected
	) => {
		return update(results, {
			[SELECT_ALL_SEARCH_RESULT]: {$set: allSelected}
		});
	}
);

export const isFromSearch = noSelector(
	getLocation
	, location => {
		if (location && location.state && location.state.search) {
			return true;
		}
		return false;
	}
);
