import { createSelector } from 'reselect';
import each from 'lodash/each';
import { emptyArray, emptyObject } from '../../common/constants';
import { getCurrentChatErrand } from '../../common/v5/helpers';
import { noSelector } from './common';
import { getChatState, getChatSocket, getStateName, getAppState } from '../util';
import { keyAgentAPIQueues, keyGetAgentAPIChatStatus } from '../constants/keys';
import { chat as chatMap } from '../actions/async/chat';
import { getAgentAreasAndDefaultEmpty } from '../actions/async/errand';
import { /*DEFAULT_ERRAND_TYPE_ICONS,*/ INSESSION_STATUS, ONLINE_STATUS, AWAY_STATUS } from '../../common/v5/constants';
import update from 'immutability-helper';

const stateName = key => getStateName(chatMap[key]);

const chatStateByKey = (state, key) => getChatState(state, stateName(key));

const getChatSessions = state => state.chat.socket.chatSessions;

const queues = state => chatStateByKey(state, keyAgentAPIQueues);

const queuesData = state => queues(state).data;

const chatStatusData = state => chatStateByKey(state, keyGetAgentAPIChatStatus);

const messages = noSelector(
	getCurrentChatErrand
	, chat => {
		if (!chat || !chat.messages || !chat.messages.length) {
			return emptyArray
		}
		return chat.messages
	}
)

export const getNewChatMessageCount = createSelector(getChatSessions, (chatSessions) => {
	let nNewMessageCount = 0;
	if(chatSessions && Object.keys(chatSessions).length > 0){
		each(chatSessions, function(chatSession) {
			if(chatSession.session){
				nNewMessageCount += chatSession.session.newMessageCount;
			}
		});
	}
	return nNewMessageCount;
});


export const getChatSessionList = createSelector(getChatSessions, (chatSessions) => {
	let chatSessionList = [];
	if(chatSessions && Object.keys(chatSessions).length > 0){
		chatSessionList = Object.keys(chatSessions);
		chatSessionList = chatSessionList.map(Number)
	}
	return chatSessionList;
});

const getChatChannelsByType = state => state.server.services.byType;

export const getChannelsInfo = createSelector(getChatChannelsByType, (channelLists) => {
	$.each(channelLists,function(id, v){
		$.each(DEFAULT_ERRAND_TYPE_ICONS, function(i, icons){
			if(i === id) {
				channelLists = update(channelLists, {
					[id]:{
						icon:{$set: icons}
					}
				});
			}
		})
		channelLists = update(channelLists, {
			[id]:{
				list:{$set: []}
			}
		});
	});
	channelLists = update(channelLists, {
		0 :{
			$set: {
				name: "Internal Chat",
				channel:"internal chat",
				icon:"icon-v5-chat",
				list:[]
			}
		}
	});
	return channelLists;
});

const getAgentPresence = state => state.chat.socket.agentPresence;

export const getFavouriteChatAgents = createSelector(getAgentPresence, (agentPresence) => {
	let agentList = {};
	if(Object.keys(agentPresence).length > 0) {
		each(agentPresence, function(agent) {
			if(agent.favourite) {
				agentList[agent.id] = agent;
			}
		});
	}
	return agentList;
});

export const getChatAgents = createSelector(getAgentPresence, (agentPresence) => {
	let agentList = {};
	if(Object.keys(agentPresence).length > 0) {
		each(agentPresence, function(agent) {
			if(agent.acceptInternalChat) {
				agentList[agent.id] = agent;
			}
		});
	}
	return agentList;
});

function appErrandInputs(state) {
	return state.app.errand.inputs;
}

function getCurrentEditNote(state) {
	return appErrandInputs(state).current_edit_note;
}

function getInternalComment(state) {
	return appErrandInputs(state).internal_comment;
}

function getInternalCommentSavedAttachments(state) {
	return appErrandInputs(state).internal_comment_saved_attachments;
}

export const internalCommentSelector = createSelector(
	getCurrentEditNote
	, getInternalComment
	, getInternalCommentSavedAttachments
	, (
		current_edit_note
		, internal_comment
		, internal_comment_saved_attachments
	) => {
		return {
			current_edit_note
			, internal_comment
			, internal_comment_saved_attachments
		};
	}
);

//get chat queued sessions
export const queuesDataWIP = state => queues(state).wip;
export const queueChatList = noSelector(
	queuesData
	, data => {
		if(!data || !data.norm) {
			return emptyArray;
		}
		let qChat = [];
		let qList = data.norm;
		if(qList && Object.keys(qList).length > 0) {
			$.each(qList,function(i, v){
				qChat.push({from: v.clientName, status: I("Waiting"), channel: "icon-v5-chat"});
			})
		}
		return qChat;
	}
)

//get chat live sessions
export const liveActiveChatsWIP = createSelector(
	chatStatusData
	, cs => {
		if(cs && cs.wip) {
			return true;
		}
		return false;
	}
)
export const liveActiveChatsData = createSelector(
	chatStatusData
	, cs => {
		const chatActiveIcon = "icon-v5-chat";
		let activeChat = [];
		if(cs.data && cs.data.Areas) {
			let chatAreas = cs.data.Areas;
			$.each(chatAreas,function(i, v){
				if(typeof v === "object") {
					$.each(v.sessions,function(i, c){
						//TODO: have video call or not flag to define icon / session type
						if(c.status === "STARTED") {
							activeChat.push({from: (c.client ? c.client.name : ""), group: v.name, agent: (c.agent ? c.agent.name : ""), icon: chatActiveIcon, status: INSESSION_STATUS});
						}
					});
				}
			});
			return activeChat;
		}
		return emptyArray;
	}
);

export const totalQueueChats = noSelector(
	queuesData
	, data => {
		if (!data || !data.list || !data.list.length) {
			return 0;
		}
		return data.list.length;
	}
);

export const getWorkflowRoot = store => getAppState(store, 'workflow');

//to get group queue
export const chatAreasInfo = createSelector(
	chatStatusData
	, getWorkflowRoot
	, (cs, wf) => {
		const areas = getAgentAreasAndDefaultEmpty(wf);
		let areaArr = [];
		if(areas && areas.length > 0) {
			const allAreas = areas[0].Areas;
			if(allAreas) {
				for(let i=0; i<allAreas.length; i++) {
					let area = allAreas[i];
					areaArr.push(area.Id);
				}
			}
		}
		let groupQueueData = [];
		if(cs.data && cs.data.Areas) {
			let chatAreas = cs.data.Areas;
			let agentsOnline = 0;
			$.each(chatAreas,function(i, v){
				if(areaArr.includes(v.id) && typeof v === "object") {
					let chatQ = v.queue.length;
					let chatSessions = 0;
					$.each(v.sessions,function(i, c){
						if(c.status === "STARTED") {
							chatSessions++;
						}
					});
					let cTxt = I("No agents online")
					agentsOnline = v.agents ? v.agents.length : 0;
					if(agentsOnline > 1) {
						cTxt = I("agents online");
					} else {
						cTxt = I("agent online");
					}
					//only showing areas with agent/s online
					if(agentsOnline > 0) {
						groupQueueData.push({area: v.name, queue: chatQ, active: chatSessions, agents: agentsOnline,  customText: cTxt, channel: "icon-v5-chat"});
					}
				}
			});
			return groupQueueData;
		}
		return groupQueueData;
	}
)

//to get agent queue
export const getAgentAreaChats = createSelector(
	chatStatusData
	, getChatSocket
	, getWorkflowRoot
	, (cs, socketData, wf) => {
		const areas = getAgentAreasAndDefaultEmpty(wf);
		let areaArr = [];
		if(areas && areas.length > 0) {
			const allAreas = areas[0].Areas;
			if(allAreas) {
				for(let i=0; i<allAreas.length; i++) {
					let area = allAreas[i];
					areaArr.push(area.Id);
				}
			}
		}
		if(cs.data && cs.data.Areas) {
			let chatAreas = cs.data.Areas;
			let agents = [],  agentQueueData = [];
			let icon = "";
			$.each(chatAreas,function(i, v){
				if(areaArr.includes(v.id) && typeof v === "object") {
					if(v.agents && v.agents.length > 0) {
						$.each(v.agents, function (n, a) {
							if (!agents.find(agent => agent.id === a.id)) {
								a.areaList = a.areaNames;
								if(a.status.className === "available") {
									a.chatStatus = ONLINE_STATUS;
								} else {
									a.chatStatus = AWAY_STATUS;
								}
								agents.push(a);
							}
						});
					}
				}
			});
			//merge with other agents data from socket
			if(socketData && socketData.agentPresence) {
				$.each(socketData.agentPresence,function(i, v){
					if (!agents.find(agent => agent.id === v.id)) {
						v.name = v.userName;
						v.areaList = v.areaList;
						v.chatStatus = v.online ? ONLINE_STATUS : AWAY_STATUS;
						agents.push(v);
					}
				});
			}
			$.each(agents, function (i, v) {
				let chatClients = [], clientsName = "";
				$.each(v.idChats, function (i, c) {
					//only chat that still in session
					if (c.Status === "STARTED" && c.ClientName) {
						chatClients.push(c.ClientName);
					}
					clientsName = chatClients.join(", ");
				});
				if(chatClients.length > 0) {
					v.chatStatus = INSESSION_STATUS;
					icon = "icon-v5-chat";
				} else {
					icon = "";
				}
				agentQueueData.push({agent: v.name, status: v.chatStatus, icon: icon, group: v.areaList, from: clientsName, channel: "icon-v5-chat"});
			});
			return agentQueueData;
		}
		return emptyArray;
	}
)

export const clientMessagesMemo = createSelector(
	messages
	, msgs => {
		const clientMessages = [];
		each(msgs, m => {
			if (m.fromClient) {
				clientMessages.push(m);
			}
		});
		if (!clientMessages.length) {
			return emptyArray;
		}
		return clientMessages;
	}
);

export const lastClientMessageMemo = createSelector(
	clientMessagesMemo
	, messages => {
		const { length } = messages;
		if (!length) {
			return;
		}
		return messages[length - 1];
	}
);

export const isScreenSharingMode = createSelector(getChatSocket, (chatSocket) => {
	if(chatSocket.agentSharingScreen || chatSocket.clientSharingScreen) {
		return true;
	}
	return false;
});

export const isCoBrowsingMode = createSelector(getChatSocket, (chatSocket) => {
	if(chatSocket.showCoBrowseFrame) {
		return true;
	}
	return false;
});
