import update from "immutability-helper";
import { createSelector } from 'reselect';
import memoizeOne from 'memoize-one';
import { replaceString } from '../../common/helpers';
import { UNKNOWN_PHOTO_LINK, emptyObject } from '../../common/v5/constants';
import { I, L } from '../../common/v5/config';
import { hasPrefix } from '../../common/v5/helpers';
import { numberToTime } from '../../reactcomponents/common';
import { getStateName, reduxCreators } from '../util';

export const KEY_PREFIX = 'key_'
	, UNFORMATTED_SUFFIX = '_unformatted'
	, GROUP_NAME_SUFFIX = '_name'
	;
// return the unformatted key field.
export function unformattedKey(key) {
	return KEY_PREFIX + key + UNFORMATTED_SUFFIX;
}

export function groupNameKey(groupKey) {
	return groupKey + GROUP_NAME_SUFFIX;
}

export function isErrandViewOnly(state) {
	return state.server.errandViewOnly;
}

export function replaceName(src, replacement) {
	return replaceString(src, "{NAME}", replacement);
}

////////////////////////////////////////////////////////////////////////////////
// new data processor
export function convertToNestedData(endpointData, includeGroupTotalRow) {
	const { data, groups, keys } = sortReportData(endpointData)
		, { groupTotal, total } = endpointData
		, settings = [groups, keys, groupTotal, total, includeGroupTotalRow]
		, creator = new nestedTableDataCreator(settings)
		, generator = new nestedDataGenerator(creator)
		;
	$.each(data, (i, v) => {
		generator.createInnerMost(v);
	});
	return [generator.finalize(), creator.createExtra()]
}

const ORDER = "order"
	, UNTAGGED = "(untagged)"
	, UNDEFINED = "undefined"
	, FUNCTION = "function"
	, hasSub = {hasSub: true}
	, totalRow = {id: "total", name: I("Total"), isTotal: true}
	, createSorter = field => (a, b) => a[field] > b[field]
	, sortByOrder = createSorter(ORDER)
	, createSortFieldGetter = arrayFields => {
		let primary = 0
			, secondary = 0
			;
		return () => {
			if (primary >= arrayFields.length) {
				return false
			}
			const { id, orders } = arrayFields[primary];
			if (!orders || secondary >= orders.length) {
				return false;
			}
			const orderField = orders[secondary++];
			primary++;
			if (!isSortableField(orderField, id)) {
				return createNoSortFieldIdentifier(id);
			}
			return orderField;
		}
	}
	, isSortableField = (field, id) => hasPrefix(field, ORDER) || field === id
	, createNoSortFieldIdentifier = id => ({id})
	, hasValidFieldForSorting = sortField => typeof sortField.id === UNDEFINED
	, isEmptyStringOrUntagged = data => data === "" || data === UNTAGGED
	, createSortByGroupNmeOrID = groupID => (a, b) => {
		const groupName = groupID + "_name";
		let orderBy;
		if (typeof a[groupName] !== UNDEFINED) {
			orderBy = groupName;
		} else {
			orderBy = groupID;
		}
		// always sort empty string or untagged string to last.
		if (isEmptyStringOrUntagged(a[orderBy])) {
			return 1;
		} else if (isEmptyStringOrUntagged(b[orderBy])) {
			return -1;
		}
		const aSmall = a[orderBy].toLowerCase()
			, bSmall = b[orderBy].toLowerCase()
			;
		if (aSmall > bSmall) {
			return 1;
		} else if (aSmall < bSmall) {
			return -1;
		}
		return 0;
	}
	;
function sortReportData({ data, groupTotal, header, total }) {
	const { groups, keys } = header
		, sortedGroups = groups.slice().sort(sortByOrder)
		, sortedKeys = keys.slice().sort(sortByOrder)
		, sortedData = data.slice().sort((a, b) => {
			const recursiveSort = (a, b, getSortField) => {
					const sortField = getSortField();
					if (!sortField) {
						return 0;
					}
					if (!hasValidFieldForSorting(sortField)) {
						// no special field to be used as order field, sort
						// base on string and backend is expected to return
						// groupX_name as string or groupX as string.
						return createSortByGroupNmeOrID(sortField.id)(a, b);
					}
					if (a[sortField] > b[sortField]) {
						return 1;
					} else if (a[sortField] < b[sortField]) {
						return -1;
					}
					return recursiveSort(a, b, getSortField);
				}
				;
			return recursiveSort(a, b, createSortFieldGetter(sortedGroups));
		})
		;
	return {data: sortedData, groups: sortedGroups, keys: sortedKeys};
}

class nestedTableDataCreator {
	constructor(settings) {
		const [
				sortedGroups
				, sortedKeys
				, groupTotal
				, total
				, includeGroupTotalRow
			] = settings
			, [ arrayKey, arrayObjects ] = normalizeAndTranslateSortedData(sortedKeys)
			, [
				arrayGroup
				, normGroup
				, headers
				, maxColumnSize
				, pivots
				, nonPivotGroup
			] = normalizeSortedGroup(sortedGroups, arrayObjects, 1)
			;
		this.arrayKey = arrayKey;
		this.normGroup = normGroup;
		this.arrayGroup = arrayGroup;
		this.headers = headers;
		this.maxColumnSize = maxColumnSize;
		this.pivots = pivots;
		this.nonPivotGroup = nonPivotGroup;
		this.groupTotal = groupTotal;
		this.total = total;
		this.includeGroupTotalRow = includeGroupTotalRow;
	}
	createGroupObjectWithDataAndGroupId(data, groupId) {
		const { orders } = this.normGroup[groupId]
			, id = data[groupId]
			;
		let name = data[groupId+"_name"]
			;
		if (typeof name === UNDEFINED) {
			name = id;
		}
		return createIdNameOrderObject(
			id
			, name
			, this.createOrderValue(orders, data, groupId)
		);
	}
	createOrderValue(orders, data, groupId) {
		if (orders.length > 1) {
			let values = [];
			$.each(orders, (i, v) => {
				values.push(data[v]);
			});
			return values.join(",");
		}
		const firstItemField = orders[0];
		if (isSortableField(firstItemField, groupId)) {
			return data[firstItemField];
		}
		return data[groupId];
	}
	createObjectWithGroups(object, data, groupIds) {
		if (!object) {
			object = {};
		}
		$.each(groupIds, (i, v) => {
			object[v] = this.createGroupObjectWithDataAndGroupId(data, v);
		});
		return object;
	}
	createRowDataWithOptionalExpand(data, groupId, expand) {
		const object = this.createObjectWithGroups({}, data, [groupId]);
		if (expand) {
			object.expand = expand;
		}
		$.each(this.arrayKey, (i, v) => {
			object[v] = this.groupTotal[v][groupId][object[groupId].id];
		});
		return object;
	}
	createInnerMostRowData(data, groupIds) {
		const object = this.createObjectWithGroups({}, data, groupIds);
		$.each(this.arrayKey, (i, v) => {
			object[v] = data[v];
		});
		return object;
	}
	createTableData(data, headerIndex) {
		return {data, header: this.headers[headerIndex]};
	}
	createFinalTotal(groupId) {
		return update(this.total, {$merge: {
			[groupId]: totalRow
			, isTotal: true
		}});
	}
	createExtra() {
		return {
			totalColumn: this.maxColumnSize
			, totalKeyColumn: this.arrayKey.length
		};
	}
}

function normalizeAndTranslateSortedData(sortedData) {
	return normalizeAndTranslateSortedDataById(sortedData, "id", "name");
}

function normalizeAndTranslateSortedDataById(sortedData, idKey, translateField) {
	let arrayIds = []
		, arrayObjects = []
		, object = {}
		;
	$.each(sortedData, (i, v) => {
		const id = v[idKey]
			, translated = L(v[translateField])
			, newValue = update(v, {[translateField]: {$set: translated}})
			;
		arrayIds.push(id);
		arrayObjects.push(newValue);
		object[id] = newValue;
	});
	return [arrayIds, arrayObjects, object];
}

function normalizeSortedGroup(sortedGroups, sortedKeys, maxPivot) {
	let sortedId = []
		, groups = []
		, pivots = []
		, nonPivot = []
		, normalized = {}
		, header
		, maxColumnGroupSize = 1
		;
	sortedGroups.reduce((previous, v, index) => {
		const { id, name: noTranslateName } = v
			, name = L(noTranslateName)
			;
		let next = previous;
		sortedId.push(id);
		normalized[id] = v;
		if (validUseAsPivotGroup(sortedGroups, maxPivot, index)) {
			pivots.push(id);
			next = new nestedHeaderCreator(previous, id, name);
		} else {
			nonPivot.push(id);
			groups.push({id, name, isGroup: true});
			if (lastItemIndex(sortedGroups) === index) {
				if (groups.length > 1) {
					maxColumnGroupSize = groups.length;
				}
				if (!next) {
					next = new nestedHeaderCreator();
				}
				header = next.create(groups, sortedKeys);
			}
		}
		return next;
	}, false);
	return [
		sortedId
		, normalized
		, header
		, maxColumnGroupSize + sortedKeys.length
		, pivots
		, nonPivot
	];
}

function validUseAsPivotGroup(sortedGroups, maxPivot, index) {
	return index !== lastItemIndex(sortedGroups) && index < maxPivot;
}

class nestedHeaderCreator {
	constructor(chain, id, name) {
		this.chain = chain;
		this.id = id;
		this.name = name;
	}
	create(groups, sortedKeys, header) {
		header = this.pushToFront(groups, sortedKeys, header);
		return this.creating(groups, sortedKeys, header);
	}
	pushToFront(groups, sortedKeys, header) {
		const groupsAndKeys = groups.concat(sortedKeys);
		if (!header) {
			header = [groupsAndKeys];
		} else {
			header.unshift(groupsAndKeys);
		}
		return header;
	}
	creating(groupHeaders, sortedKeys, header) {
		if (typeof this.id === UNDEFINED) {
			return header;
		}
		const groups = [{
				id: this.id
				, name: this.name
				, isGroup: hasSub
				, groupHeaders
			}]
			;
		return this.createBase(groups, sortedKeys, header);
	}
	createBase(groupHeaders, sortedKeys, header) {
		header = this.pushToFront(groupHeaders, sortedKeys, header);
		if (this.chain) {
			return this.chain.creating(groupHeaders, sortedKeys, header);
		}
		return header;
	}
}

function createIdNameOrderObject(id, name, order) {
	const object = {id, name};
	if (typeof order !== UNDEFINED) {
		object.order = order;
	}
	return object;
}

function lastItemIndex(array) {
	return array.length - 1;
}

class nestedDataGenerator {
	constructor(creator, groupId, index, chain) {
		const { pivots, nonPivotGroup, includeGroupTotalRow } = creator;
		this.creator = creator;
		this.includeGroupTotalRow = includeGroupTotalRow;
		this.pivots = pivots;
		this.nonPivotGroup = nonPivotGroup;
		this.data = [];
		if (typeof groupId === UNDEFINED) {
			if (!pivots || !pivots.length) {
				this.index = 0;
			} else {
				this.index = pivots.length;
			}
			this.chain = this.createPivotReducer(pivots, false);
		} else {
			this.groupId = groupId;
			this.index = index;
			this.chain = chain;
		}
	}
	finalize() {
		if (!this.data.length) {
			return this.createEmptyDataRowWithTotal();
		}
		const { previous } = this
			;
		let lastTableData = this.creator.createTableData(
				this.getDataWithTotalRowIfNoMoreChain()
				, this.getHeaderIndex()
			)
			, current = this.chain
			;
		while(current) {
			const { creator, groupId, chain } = current;
			current.pushToBack(creator.createRowDataWithOptionalExpand(
				previous
				, groupId
				, lastTableData
			));
			lastTableData = creator.createTableData(
				current.getDataWithTotalRowIfNoMoreChain()
				, current.getHeaderIndex()
			);
			current = chain;
		}
		return lastTableData;
	}
	createEmptyDataRowWithTotal() {
		const outerMost = this.getLastChain();
		return outerMost.creator.createTableData(
			outerMost.getDataWithTotalRowIfNoMoreChain()
			, outerMost.getHeaderIndex()
		);
	}
	getLastChain() {
		let current = this;
		while(current.chain) {
			current = current.chain;
		}
		return current;
	}
	getDataWithTotalRowIfNoMoreChain() {
		const { chain, creator } = this;
		if (!chain) {
			this.pushToBack(creator.createFinalTotal(this.getValidGroupId()));
		}
		return this.data;
	}
	getValidGroupId() {
		if (typeof this.groupId === UNDEFINED) {
			return this.nonPivotGroup[0];
		}
		return this.groupId;
	}
	createInnerMost(data) {
		const groupIds = this.nonPivotGroup;
		if (!this.previous) {
			this.pushToBack(data, groupIds);
			return;
		}
		const different = this.differentPivotGroup(data);
		if (different && this.pivots.length) {
			const { index } = different
				, groupId = this.pivots[index]
				;
			this.chain = this.createPivotReducer(
				this.pivots.slice(index+1)
				, this.terminalPivot(groupId)
			);
		}
		this.pushToBack(data, groupIds);
	}
	createPivotReducer(pivots, initialReducer) {
		return pivots.reduce((previous, groupId, i) => new nestedDataGenerator(
			this.creator
			, groupId
			, i
			, previous
		), initialReducer);
	}
	pushToBack(data, groupIds) {
		if (typeof groupIds !== UNDEFINED) {
			this.previous = data;
			data = this.creator.createInnerMostRowData(data, groupIds);
		}
		this.data.push(data);
	}
	differentPivotGroup(current) {
		if (!this.previous) {
			return {index: 0};
		}
		let found;
		$.each(this.pivots, (index, v) => {
			// NOTE: here divert from original code where uniqueness of row data
			// depend on the groupX instead of groupX_name (even if it exist).
			if (this.previous[v] !== current[v]) {
				found = {index};
				return false;
			}
		});
		return found;
	}
	terminalPivot(groupId) {
		let current = this
			, { previous } = current
			, lastTableData
			, lastRowData
			;
		while(current && current.groupId !== groupId) {
			const { creator } = current;
			if (lastTableData) {
				current.addPivotRow(previous, current.groupId, lastTableData);
			}
			lastTableData = creator.createTableData(
				current.data
				, current.getHeaderIndex()
			);
			current.data = [];
			current = current.chain;
		}
		if (lastTableData) {
			current.addPivotRow(previous, current.groupId, lastTableData);
		}
		return current;
	}
	addPivotRow(previous, groupId, tableData) {
		const rowData = this.creator.createRowDataWithOptionalExpand(
			previous
			, groupId
			, tableData
		);
		this.pushToBack(rowData);
		if (this.includeGroupTotalRow && tableData.data.length > 1) {
			this.pushToBack(this.createGroupTotalRow(
				update(rowData, {$unset: ["expand"]})
				, groupId
				, this.data.length-1
			));
		}
	}
	createGroupTotalRow(data, groupId, index) {
		return update(data, {$merge: {
			[groupId]: totalRow
			, isGroupTotal: {index}
		}});
	}
	getHeaderIndex() {
		if (!this.pivots || !this.pivots.length) {
			return 0;
		}
		return this.index;
	}
}

////////////////////////////////////////////////////////////////////////////////

const endpointIsFetching = {reason: I("Data is being fetched...")};

export function makeNotReadinessSelector(asyncStateGetter) {
	const wipGetter = state => asyncStateGetter(state).wip
		, errGetter = state => asyncStateGetter(state).err
		;
	return createSelector(
		wipGetter
		, errGetter
		, (wip, err) => {
			if (!wip && !err) {
				return false;
			} else if (wip) {
				return endpointIsFetching;
			}
			return {
				reason: I("Error when fetching: {ERROR}")
					.replace("{ERROR}", err)
			};
		}
	);
}

// same pattern as createSelector but without memoize. Useful for not heavy
// function and also if the selector return a literal type like integer, string,
// and boolean because redux connect and react component easily can detect
// whether there is changes on this type. If the selector return object or array
// then do NOT use this function. The idea is to be able to change to
// createSelector easily if memoize is needed.
export const noSelector = (...args) => (state, props) => {
	const lastIndex = args.length - 1
		, lastArgs = args.slice(0, lastIndex).reduce((acc, fn) => {
			acc.push(fn(state, props))
			return acc;
		}, [])
		;
	return args[lastIndex](...lastArgs);
};

// this special selector allows any selector to return another selector which
// will be executed. The same result may be achieved by putting this result
// selector as one of the argument of the parent selector where this selector
// result will be used instead of directly return the selector function.
// HOWEVER, returning a selector function instead of putting it as one of the
// argument has one advantage which is the selector is not executed unless it is
// needed too, which is the state / props that result returning the selector.
// Need more tests to proof its effectiveness as no such function in the wild.
const selectSelectorBase = selector => (...args) => {
	const createdSelector = selector(...args)
		, fn = (...args) => {
			const selectorResult = createdSelector(...args);
			if (typeof selectorResult === "function") {
				return selectorResult(...args);
			}
			return selectorResult;
		}
		;
	for (var key in createdSelector) {
		fn[key] = createdSelector[key];
	}
	return fn;
};

export const selectNoSelector = selectSelectorBase(noSelector);

export const selectCreateSelector = selectSelectorBase(createSelector);

const keyAverageErrandProcessTime = "average_errand_process_time"
	, keyClosed = "closed"
	, leaderboardGroupName = "group0"
	, keyNumberOfContacts = "number_of_contacts"
	;
export const convertSystemReportLeaderboardDataToLeaderboardData = (chartData, agents) => {
	const usernameKey = groupNameKey(leaderboardGroupName)
		, handlingTimeKey = unformattedKey(keyAverageErrandProcessTime)
		, closedErrandsKey = unformattedKey(keyClosed)
		, { data } = chartData
		;
	let result = [];
	$.each(data, (i, v) => {
		const id = v[leaderboardGroupName];
		if (!id) {
			// do not count no owner
			// NOTE: this front-end filter is NOT good idea because it requires
			// both backend and front-end to handle system report leaderboard,
			// another solution is to create a new report agents-group that
			// don't return no-owner agents BUT such a group does not seem
			// useful to other reports and some more this no-owner result may be
			// a plus for this new leaderboard - just remove this filter. So no
			// conclusion.
			return;
		}
		const agent = agents[id];
		let img;
		if (agent) {
			img = agent.Avatar;
		}
		if (!img) {
			img = UNKNOWN_PHOTO_LINK;
		}
		result.push({
			id
			, name: v[usernameKey]
			, img
			, handlingTime: numberToTime(v[handlingTimeKey])
			, closedErrands: v[closedErrandsKey]
		});
	});
	return result;
};

export const convertSystemReportNumberOfContactsToContactsToplistData = (chartData, clients) => {
	const clientKey = groupNameKey(leaderboardGroupName)
		, numnerOfContactsKey = unformattedKey(keyNumberOfContacts)
		, { data } = chartData
		;
	let result = [];
	$.each(data, (i, v) => {
		const id = v[leaderboardGroupName];
		const count = v[numnerOfContactsKey];
		let index = result.findIndex(v => v.id === id);
		if (index >= 0) {
			result[index].errands += count;
		} else {
			let img;
			if (clients) {
				const client = clients[v.email_fkey];
				if (client && client.avatar) {
					img = client.avatar.url;
				}
			}
			if (!img) {
				img = UNKNOWN_PHOTO_LINK;
			}
			result.push({
				id
				, name: v[clientKey]
				, img: img
				, errands: count
			});
		}
	});
	return result;
};

const wfSettings = state => state.app.workflow.fetchWfSettings;

export const wfSettingsData = noSelector(
	wfSettings
	, ({ data }) => data ? data : emptyObject
);

export function arrayAreasToAreaObjects(array) {
	if (!array || !array.length) {
		return emptyObject;
	}
	let object = {};
	$.each(array, (i, v) => {
		object[v] = true;
	});
	return object;
}

export const commonSelectorsCreator = (rootSelector, branch, reduxMap) => {
	const asyncMap = reduxCreators(reduxMap)
	const _root = store => rootSelector(store)[branch]
	const _edit = state => _root(state).edit
	const _stateName = key => getStateName(asyncMap[key])
	const _stateByKey = (store, key) => _root(store)[_stateName(key)]
	return {
		asyncMap,
		root: _root,
		edit: _edit,
		editData: state => _edit(state).data,
		stateName: _stateName,
		stateByKey: _stateByKey,
		dataSelector: key => state => _stateByKey(state, key).data
	}
}
