import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import update from 'immutability-helper'
import { emptyObject } from '../../common/constants'
import {
  CHATBOT_ROUTES_MATCH,
} from '../../common/v5/chatbotConstants'
import { noSelector } from './common'
import { getStateName, reduxCreators } from '../util'
import {
  keyAdminChatbots,
  keyAdminChatbotsQuery,
  keyAdminDeleteChatbots,
  keyAdminDeleteChatbotsIntents,
  keyAdminEditChatbots,
  keyAdminEditChatbotsIntents,
  keyAdminGetChatbotsIntents,
  keyAdminGetOneChatbot,
  keyAdminSyncChatbot,
  keyEditChatbotsTrainingphrases
} from '../constants/keys'
import { adminRoot as _adminRoot } from './admin'
import { alertData } from './hmf'
import { constants } from './server'
import { knowledgeBaseValidDataMemo, makeFeatureMemo } from './workflow'

export const BRANCH_NAME = 'chatbots'

export const adminAsyncMap = reduxCreators([
  [keyAdminChatbots, 'chatbots'],
  [keyAdminChatbotsQuery, 'queryChatbots'],
  [keyAdminDeleteChatbots, 'deleteChatbots'],
  [keyAdminDeleteChatbotsIntents, 'deleteIntents'],
  [keyAdminEditChatbots, 'editChatbots'],
  [keyAdminEditChatbotsIntents, 'editIntents'],
  [keyAdminGetChatbotsIntents, 'getIntents'],
  [keyAdminGetOneChatbot, 'getOneChatbot'],
  [keyAdminSyncChatbot, 'syncChatbot'],
  [keyEditChatbotsTrainingphrases, 'chatbotsTrainingphrases']
])

export const adminRoot = store => _adminRoot(store)[BRANCH_NAME]

export const adminAgentsState = store => adminRoot(store).agents

export const adminEditMemo = state => adminRoot(state).edit

export const adminChatbotUI = state => adminRoot(state).ui

export const adminStateName = key => getStateName(adminAsyncMap[key])

export const adminStateByKey = (
  store,
  key
) => adminRoot(store)[adminStateName(key)]

export const chatbotsMemo = createSelector(
  adminAgentsState,
  agents => agents
)

export const matchParamMemo = createMatchSelector(CHATBOT_ROUTES_MATCH)

const alertObjectData = noSelector(
  alertData,
  data => {
    if (!data) {
      return emptyObject
    }
    return data
  }
)

export const selectedClientMessageMemo = createSelector(
  alertObjectData
  , ({ selected }) => selected
)

const _constants = state => constants(state).chatbots

export const reservedEventsMemo = createSelector(
  _constants,
  ({ workflowLibraryQuestion, ...constants }) => ({
    chatbotLibQ: workflowLibraryQuestion,
    ...constants
  })
)

export const providersMemo = noSelector(
  makeFeatureMemo('chatbotProviders'),
  providers => providers
)

export const knowledgebasesMemo = noSelector(
  knowledgeBaseValidDataMemo,
  data => data
)

export const noForBotQuestionMemo = noSelector(
  _constants,
  ({ newKnowledgebaseLinks }) => newKnowledgebaseLinks
)

export const allowMultipleChatbotProvidersMemo = noSelector(
  _constants,
  ({ manyProviders }) => manyProviders
)

export const chatbotsIdListMemo = createSelector(
  chatbotsMemo,
  chatbots => {
    if (!chatbots) {
      return []
    }
    return chatbots.list;
  }
)

const chatbotIntents = state => state.app.admin.chatbots.getIntents;
export const chatbotsIntentsMemo = createSelector(
  chatbotIntents,
  intents => {
    if (!intents) {
      return []
    }
    return intents.data;
  }
)

const activeKB = state => state.app.knowledgebase;

export const chatbotsTrainingphrasesMemo = createSelector(
  chatbotsIntentsMemo,
  activeKB,
  (intents, active) => {
    if (!intents || intents.length == 0) {
      return []
    }
    if(typeof intents === 'object' && intents.length == undefined){
      return intents.training_phrases;
    }
    return [];
  }
)