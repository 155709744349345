import { SMTP } from '../../common/path'
import { emptyNormalized } from '../../common/constants'
import {
  keyAdminSmtp,
  keyAdminSaveSmtp,
  keyAdminDeleteSmtp
} from '../constants/keys'
import { noSelector } from './common'
import { selectorsCreator } from './admin'

export const BRANCH_NAME = SMTP

const selectors = selectorsCreator(BRANCH_NAME, [
  [keyAdminSmtp, 'get'],
  [keyAdminSaveSmtp, 'save'],
  [keyAdminDeleteSmtp, 'delete']
])

export const {
  asyncMap: adminAsyncMap,
  root: adminRoot,
  edit: adminEditMemo,
  editData: adminEditDataMemo,
  stateName: adminStateName,
  stateByKey: adminStateByKey
} = selectors

const { dataSelector: dataSelectorCreator } = selectors

export const adminListMemo = noSelector(
  dataSelectorCreator(keyAdminSmtp),
  list => {
    if (!list || !list.allIds || !list.allIds.length) {
      return emptyNormalized
    }
    return list
  }
)
