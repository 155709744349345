import { createSelector } from 'reselect';
import update from 'immutability-helper';
import {
	D_AREAS
	, D_CLIENT_AVATAR
	, D_FORWARD_AGENTS
	, emptyArray
	, emptyObject
} from '../../common/v5/constants';
import {
	noSelector
	, selectCreateSelector
	, selectNoSelector
} from './common';

const rootMemo = state => state.domain;

const byId = domain => state => rootMemo(state)[domain].byId;

export const areaByIdMemo = byId(D_AREAS)

export const clientAvatarByIdMemo = byId(D_CLIENT_AVATAR);
