import { createSelector } from 'reselect';

const knowledgebase = state => state.app.knowledgebase;

export const libraryFileArchivesSelector = createSelector(
	knowledgebase,
	(kb)=>{
		let fa = kb.fileArchiveImages;
		if (fa && fa.length > 0) {
			return fa;
		}
		return [];
	}
);

export const openLibraryIdSelector = createSelector(
	knowledgebase,
	(kb)=>{
		let kbt = kb.knowledgeBaseTreeList;
		if (kbt && kbt.length == 1) {
			return kbt[0].id;
		}
		return null;
	}
);

export const openQuestionIdSelector = createSelector(
	knowledgebase,
	(kb)=>{
		let kbt = kb.edit;
		if (kbt && kbt.data && kbt.data.id) {
			return kbt.data.id;
		}
		return null;
	}
);