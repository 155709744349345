import { createSelector } from 'reselect';
import update from 'immutability-helper';
import {
	getCurrentErrand,
	getDomainBasicErrands,
	currentChatErrand,
	notMineSelector,
	collaborationInputs,
	areaAgentDataSelector
} from './errand';
import { noSelector } from './common';
import {
	getValidDomainData
} from '../util';
import {
	LIGHT_NOT_MINE,
	LIGHT_NO_EE,
	LIGHT_READY,
	EXPAND_BEFORE,
	EXPAND_AFTER
} from '../../common/v5/constants';

const getEEQueriesData = state => {
	const e = state.app.errand;
	if(e.fetchExternalQueries.data) {
		return e.fetchExternalQueries.data;
	}
};

const getEEQueriesTargets = createSelector(
	getEEQueriesData,
	queriesData => {
		if(queriesData) {
			let data = [];
			if(queriesData.externalRecipients &&
				queriesData.externalRecipients.length) {
				$.each(queriesData.externalRecipients, (i,v) => {
					if(v.name) {
						data.push(v);
					} else {
						data.push(update(v, {name: {$set: v.email}}));
					}
				});
			}
			if(queriesData.internalRecipients &&
				queriesData.internalRecipients.length) {
				data.push(...queriesData.internalRecipients);
			}
			if(data.length) {
				return data;
			}
		}
	}
);

const getTotalAnnswersFromQuery = createSelector(
	getEEQueriesData,
	queriesData => {
		if(queriesData) {
			return queriesData.answered;
		}
	}
);

const getCurrentErrandCollaboration = createSelector(
	currentChatErrand,
	getCurrentErrand,
	getDomainBasicErrands,
	(chat, eid, domain) => {
		if (chat) {
			return chat.errand.data.collaboration;
		}
		const rawData = domain.byId[eid];
		if(rawData) {
			return rawData.data.collaboration;
		}
	}
);

const getSimpleCollaboration = state => {
	return state.app.errand.currentErrand.collaboration;
};

export const hasCollaborationMemo = noSelector(
	getSimpleCollaboration,
	collaboration => !!collaboration
);

const getCollaboration = createSelector(
	[
		getCurrentErrandCollaboration,
		getSimpleCollaboration
	],
	(col, simpleCol) => {
		if(!col || !simpleCol) {
			return simpleCol;
		}
		const {queries: denom, answers: num, status} = col;
		return update(simpleCol, {$merge: {denom, num, status}});
	}
);

const getTotalQueries = createSelector(
	getCollaboration,
	collaboration => {
		if(collaboration) {
			return collaboration.denom;
		}
	}
);

const getTotalReplies = createSelector(
	getCollaboration,
	collaboration => {
		if(collaboration) {
			return collaboration.num;
		}
	}
);

const getCollaborationStatus = createSelector(
	getCollaboration,
	collaboration => {
		if(collaboration) {
			return collaboration.status;
		}
		return '';
	}
);

const notReadyEESummary = {
	targets: [],
	status: '',
	queries: 0,
	answers: 0
};

export const getEESummarySelector = createSelector(
	[
		getEEQueriesTargets,
		getCollaborationStatus,
		getTotalQueries,
		getTotalReplies,
		getTotalAnnswersFromQuery
	],
	(targets, status, queries, replies, answers) => {
		if(!targets || typeof queries === 'undefined' ||
			typeof replies === 'undefined' || typeof answers === 'undefined') {
			return notReadyEESummary;
		}
		if(replies !== answers) {
			console.log('dbg: it happen inconsistent answer:', replies,
				answers);
		}
		return {targets, status, queries, answers};
	}
);

const getDomainEEList = state => state.domain.externalExpertList;

const getCurrentErrandEEList = (state, props) => {
	const list = getDomainEEList(state);
	if(list) {
		const currentID = list.byId[props.eid];
		if(currentID) {
			return currentID.list;
		}
	}
};

const defLightCondition = {
	condition: LIGHT_NO_EE
};

export const getCollaborationLightSelector = createSelector(
	[
		getCurrentErrandCollaboration,
		notMineSelector
	],
	(collaborate, notMine) => {
		if(!collaborate) {
			return defLightCondition;
		} else if(notMine) {
			return update(defLightCondition, {condition: {
				$set: LIGHT_NOT_MINE}});
		}
		return update(defLightCondition, {
			condition: {$set: LIGHT_READY},
			on: {$set: collaborate.light}
		});
	}
);

export const getDomainEEThreads = state => state.domain.externalExpertThreads;

export const getCollaborationListSelector = createSelector(
	[
		getCollaboration,
		getCurrentErrandEEList,
		getDomainEEThreads
	],
	(collaborate, list, threads) => {
		let eeList = [];
		if(typeof collaborate !== 'undefined' && collaborate.eeList) {
			if(list) {
				$.each(list, (i,v) => {
					const id = v.id,
						threadData = getValidDomainData(threads, id);
					if(threadData) {
						eeList.push({type: EXPAND_AFTER, data: threadData, id, header: v});
					} else {
						eeList.push({type: EXPAND_BEFORE, data: v, id});
					}
				});
			}
		}
		return eeList;
	}
);

export const collaborationGroupName = createSelector(
	collaborationInputs
	, areaAgentDataSelector
	, (c, agents) => {
		let sLen = c.selectedAgents.length,
			title = I("No agent selected");
		if(sLen > 0 && sLen === agents.length) {
			return I('All');
		} else if(sLen > 1) {
			return I('Multiple');
		} else if(sLen === 1) {
			$.each(agents, (i,v) => {
				if(v.Id === c.selectedAgents[0]) {
					title = v.Name;
					return false;
				}
			});
			return title;
		}
		return title;
	}
);
