import moment from 'moment';
import { createSelector } from 'reselect';
import {
	RPLY_COMMENT,
	RPLY_COLLABORATE,
	ME_CREATE,
	ME_NOTE
} from '../../common/v5/constants';
import {
	getCurrentReply,
	getAppErrand
} from './errand';
import {
	getWorkflowSettingsData
} from './workflow';
import {
	getSelectedManualCurrentReply,
	getManualErrandTranslateTo
} from './manual';

function getErrandInputTranslateTo(state) {
	return getAppErrand(state).inputs.translationTo;
}

function getCollaborationInputTranslateTo(state) {
	return getAppErrand(state).collaborationInputs.translateTo;
}

function getCurrentSession(state) {
	if(getAppErrand(state).chat){
		return getAppErrand(state).chat.sessionId;
	}
}

function getCurrentTranslateUI(state) {
	if(getAppErrand(state).ui.chatTranslation){
		return getAppErrand(state).ui.chatTranslation;
	}
}

export const ManualErrandCanTranslate = createSelector(
	[
		getSelectedManualCurrentReply,
		getWorkflowSettingsData
	],
	(currentReply, wfData) => {
		if (currentReply === ME_CREATE || currentReply === ME_NOTE || !wfData) {
			return false;
		}
		return wfData.translation;
	}
);

export const ManualErrandTranslateTo = createSelector(
	[
		getManualErrandTranslateTo
	],
	(to) => {
		return to;
	}
);

export const canTranslateSelector = createSelector(
	[
		getCurrentReply,
		getWorkflowSettingsData
	],
	(reply, wfData) => {
		if (reply === RPLY_COMMENT || !wfData) {
			return false;
		}
		return wfData.translation;
	}
);

export const translateToSelector = createSelector(
	[
		getCurrentReply,
		getErrandInputTranslateTo,
		getCollaborationInputTranslateTo
	],
	(reply, errandTranslateTo, collaborationTranslateTo) => {
		if (reply === RPLY_COMMENT) {
			return "";
		} else if (reply === RPLY_COLLABORATE) {
			return collaborationTranslateTo;
		}
		return errandTranslateTo;
	}
);

export const canTranslateChatSelector = createSelector(
	[
		getCurrentReply,
		getWorkflowSettingsData
	],
	(reply, wfData) => {
		if (reply === RPLY_COMMENT || !wfData) {
			return false;
		}
		return wfData["chat.translation"];
	}
);

export const getChatTranslateToSelector = createSelector(
	[
		getCurrentSession,
		getCurrentTranslateUI
	],
	(id, ui) => {
		if(id){
			if(typeof ui[id] !== "undefined" && typeof ui[id].to !== "undefined"){
				return ui[id].to;
			}
		}
		return "";
	}
);

export const getChatTranslateFromSelector = createSelector(
	[
		getCurrentSession,
		getCurrentTranslateUI
	],
	(id, ui) => {
		if(id){
			if(typeof ui[id] !== "undefined" && typeof ui[id].from !== "undefined"){
				return ui[id].from;
			}
		}
		return "";
	}
);

export const getChatTranslateDetectSelector = createSelector(
	[
		getCurrentSession,
		getCurrentTranslateUI
	],
	(id, ui) => {
		if(id){
			if(typeof ui[id] !== "undefined" && typeof ui[id].fromCust !== "undefined"){
				return ui[id].fromCust;
			}
		}
		return "";
	}
);

export const getAgentChatTranslatedText = createSelector(
	[
		getCurrentSession,
		getCurrentTranslateUI
	],
	(id, ui) => {
		if(id){
			if(typeof ui[id] !== "undefined" && typeof ui[id].agentText !== "undefined"){
				return ui[id].agentText;
			}
		}
		return "";
	}
)
