import update from 'immutability-helper';
import '../../common/webpack-public-path';
import { emptyObject } from '../../common/v5/constants';
import { DEV_TEST } from '../constants/constants';

let devtestData = emptyObject;

if (localStorage.getItem("debugc3")) {
	import(/* webpackChunkName: "devtestdata" */ './devtestdata')
	.then(function(data) {
		devtestData = data.default;
		console.log('devtest data loaded');
	}.bind(this)).catch(function(err) {
		console.log('An error occurred while loading tester data', err);
	}.bind(this));
	window.DebugC3 = true;
}

const devTestActionCreator = payload => ({type: DEV_TEST, payload});

export const devtestAction = action => {
	let result = devtestData[action];
	if (typeof result === "function") {
		result = result(devTestActionCreator);
		if (typeof result === "function") {
			return result;
		}
	}
	return devTestActionCreator(result);
};

export const devtestReducer = (state, { payload, type }) => {
	if (type !== DEV_TEST || !payload) {
		return state;
	} else if (!payload.length) {
		return update(state, payload);
	}
	$.each(payload, (i, v) => {
		state = update(state, v);
	});
	return state;
};
