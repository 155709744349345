import each from 'lodash/each'
import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import { emptyNormalized } from '../../common/constants'
import { SMTP_ROUTES_MATCH } from '../../common/v5/smtpConstants'
import {
  keyAWSSESIdentities,
  keyAWSSESIdentity,
  keyCreateAWSSESIdentity,
  keyDeleteAWSSESIdentity
} from '../constants/keys'
import { noSelector } from './common'
import { selectorsCreator } from './admin'

export const BRANCH_NAME = 'awsses'
export const BRANCH_IDS_BY_REGION = 'idsByRegion'
export const BRANCH_IDENTITY_BY_REGION = 'idByRegion'

const selectors = selectorsCreator(BRANCH_NAME, [
  [keyAWSSESIdentities, 'getIdentities'],
  [keyAWSSESIdentity, 'getIdentity'],
  [keyCreateAWSSESIdentity, 'createIdentity'],
  [keyDeleteAWSSESIdentity, 'deleteIdentity']
])

export const {
  asyncMap: adminAsyncMap,
  edit: adminEditMemo,
  editData: adminEditDataMemo,
  root: adminRoot,
  stateName: adminStateName,
  stateByKey: adminStateByKey
} = selectors

export const regionIdentitiesMemo = state => adminRoot(state)[BRANCH_IDS_BY_REGION]

const regionIdentity = state => adminRoot(state)[BRANCH_IDENTITY_BY_REGION]

const matchParam = createMatchSelector(SMTP_ROUTES_MATCH)

export const matchParamMemo = createSelector(
  matchParam,
  match => {
    if (match && match.params) {
      const converted = {}
      each(match.params, (v, k) => {
        if (typeof v !== 'undefined') {
          converted[k] = decodeURIComponent(v)
        }
      })
      return converted
    }
  }
)

export const identitiesMemo = noSelector(
  regionIdentitiesMemo,
  matchParamMemo,
  (identities, match) => {
    if (!match || !match.region || !identities[match.region]) {
      return emptyNormalized
    }
    return identities[match.region]
  }
)

const getIdentityByRegionAndID = (
  identityState,
  region,
  id
) => identityState[region][id]

export const identityMemo = noSelector(
  regionIdentity,
  matchParamMemo,
  (identity, match) => {
    if (!match || !match.identities || !identity[match.region]) {
      return false
    }
    return getIdentityByRegionAndID(identity, match.region, match.identities)
  }
)
