import each from 'lodash/each'
import { createSelector } from 'reselect'
import { emptyArray, emptyNormalized } from '../../common/constants'
import { keyAllConstants, keyConstants } from '../constants/keys'
import { getAppRoot } from '../util'
import { commonSelectorsCreator, noSelector } from './common'

export const BRANCH_NAME = 'constants'
export const BRANCH_BY_NAME = 'byName'

export const C_AWSSES = 'awsses'

const selectors = commonSelectorsCreator(getAppRoot, BRANCH_NAME, [
  [keyAllConstants, 'all'],
  [keyConstants, 'get']
])

export const {
  asyncMap: appAsyncMap,
  root: appRoot,
  stateName: appStateName,
  stateByKey: appStateByKey
} = selectors

export const byNameMemo = state => appRoot(state)[BRANCH_BY_NAME]

const emptyAWSSES = {
  ...emptyNormalized,
  ports: emptyArray
}

export const awssesMemo = noSelector(
  byNameMemo,
  byName => {
    const v = byName[C_AWSSES]
    if (v) {
      return v
    }
    return emptyAWSSES
  }
)

export const byRegionIdAWSSESMemo = createSelector(
  awssesMemo,
  ({ byId }) => {
    const byRegion = {}
    each(byId, ({ regionId }, smtpName) => {
      byRegion[regionId] = smtpName
    })
    return byRegion
  }
)

export const allMemo = byNameMemo
