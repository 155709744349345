import { createSelector } from 'reselect'
import { createMatchSelector } from 'connected-react-router'
import update from 'immutability-helper'
import each from 'lodash/each'
import {
  emptyArray,
  emptyNormalized,
  emptyObject
} from '../../common/constants'
import {
  RECEIPT_GREETING_ROUTES_MATCH,
  tabToShort
} from '../../common/v5/receiptGreetingConstants'
import { noSelector } from './common'
import { getStateName, reduxCreators } from '../util'
import {
  keyAdminFilesAreaarchiveimages,
  keyAdminDeleteReceiptgreetings,
  keyAdminRGServiceaccounts,
  keyAdminReceiptgreetings,
  keyAdminSaveReceiptgreetings
} from '../constants/keys'
import { selectorsCreator } from './admin'
import { servicesNormTypeMemo } from './server'

export const BRANCH_NAME = 'receiptgreetings'

const selectors = selectorsCreator(BRANCH_NAME, [
  [keyAdminFilesAreaarchiveimages, 'areaFileArchiveImages'],
  [keyAdminReceiptgreetings, 'get'],
  [keyAdminSaveReceiptgreetings, 'save'],
  [keyAdminRGServiceaccounts, 'serviceAccounts'],
  [keyAdminDeleteReceiptgreetings, 'delete']
])

export const {
  asyncMap: adminAsyncMap,
  root: adminRoot,
  edit: adminEditMemo,
  editData: adminEditDataMemo,
  stateName: adminStateName,
  stateByKey: adminStateByKey
} = selectors

const { dataSelector: dataSelectorCreator } = selectors

const adminAllListsMemo = dataSelectorCreator(keyAdminReceiptgreetings)

const oneReceiptList = (allLists, receipt) => {
  if (!allLists || !allLists[tabToShort[receipt]]) {
    return emptyNormalized
  }
  return allLists[tabToShort[receipt]]
}

export const adminReceiptByIdSelector = noSelector(
  adminAllListsMemo,
  (_, { tab }) => tab,
  (data, receipt) => oneReceiptList(data, receipt).byId
)

export const matchParamMemo = createMatchSelector(RECEIPT_GREETING_ROUTES_MATCH)

export const adminListMemo = createSelector(
  adminAllListsMemo,
  matchParamMemo,
  (allLists, match) => {
    if (!allLists || !match || !match.params || !match.params[0]) {
      return emptyNormalized
    }
    const shortName = tabToShort[match.params[0]]
    if (!shortName) {
      return emptyNormalized
    }
    return allLists[shortName]
  }
)

const serviceTypeAccountsMemo = noSelector(
  dataSelectorCreator(keyAdminRGServiceaccounts),
  serviceAccounts => {
    if (!serviceAccounts) {
      return emptyObject
    }
    return serviceAccounts
  }
)

export const servicesAndAccountsMemo = createSelector(
  servicesNormTypeMemo,
  serviceTypeAccountsMemo,
  ({ allTypes, byType }, accounts) => {
    const allIds = []
    each(allTypes, t => {
      if (typeof accounts[t] !== 'undefined') {
        allIds.push(t)
      }
    })
    return update(accounts, { $merge: { service: { allIds, byId: byType } } })
  }
)

export const fileArchiveImagesMemo = noSelector(
  dataSelectorCreator(keyAdminFilesAreaarchiveimages),
  data => {
    if (data) {
      return data
    }
    return emptyArray
  }
)
