import { createSelector } from 'reselect'
import { noSelector } from './common'

export const getServerState = store => store.server

const createBranchSelector = branch => state => getServerState(state)[branch]

export const actionsLinkMemo = createBranchSelector('actionsLink')

export const getMainMenuById = state => getServerState(state).mainMenuById

const getFeatures = state => getServerState(state).features

export const adminMenus = state => getServerState(state).menuAdmin

export const constants = state => getServerState(state).constants

const services = createBranchSelector('services')

export const serviceTypes = state => services(state).constants.Workflow.Errand

export const servicesByType = state => services(state).byType

const serviceAllTypes = state => services(state).allTypes

export const servicesNormTypeMemo = createSelector(
  serviceAllTypes,
  servicesByType,
  (allTypes, byType) => ({ allTypes, byType })
)

export const featureSelector = noSelector(
  getFeatures,
  features => features
)

const createFeatureSelector = field => state => featureSelector(state)[field]

export const isTwilioCallEnabled = createFeatureSelector('callEnableTwilio')

export const canInternalGroupChat = createFeatureSelector('chat.group-chat-with-client')

export const preselectChatAssociatedErrands = createFeatureSelector('chat.auto-preselect-associated-errands')

export const allowTextCommentChatRating = createFeatureSelector('chat.allow-rating-text-comment')

export const orgAreaMemo = createBranchSelector('orgArea')

export const orgCompleteListsAreaMemo = createBranchSelector('orgCompleteListsArea')
